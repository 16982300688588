<template>
    <div class="MoreProductList">
        <el-page-header @back="backPrivious" content=""></el-page-header>
        <SearchBar :ImageSearchtype="'EnterpriseRelatedProducts'" class="search-input "
            :ImageUploadStyle="ImageUploadStyle">
            <input slot="inputBox" style="padding-left: 20px; width: 1000px;" type="text" placeholder="请输入"
                v-model.trim="searchValue" @change="searchRelevantProduct" />
            <el-button slot="buttonBox" type="primary" @click="searchBtn">搜索</el-button>
        </SearchBar>
        <div class="MoreProductList">
            <ProductCard :list="tabContainerList"></ProductCard>
            <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </div>
    </div>
</template>

<script>
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options"
export default {
    name: 'MoreProductList',
    mixins: [UrlDecodeId, PaginationRetention],
    data() {
        return {
            tabContainerList: [],
            searchValue: '',
            ImageUploadStyle: {
                'width': '80%',
                'left': 0,
                'text-align': 'center',
            },
        }
    },
    created() {
        this.$setStorage('EnterpriseRelatedId', this.queryId)
    },
    watch: {
        searchValue() {
            if (!this.searchValue) {
                this.getDate()
            }
        }
    },
    methods: {
        getDate() {
            this.openEnterpriseProductList(this.paging, this.pagingSize);
        },
        // 首页企业开放商品搜索列表接口
        openEnterpriseProductList(page = this.page, size = this.size) {
            let that = this, data = {
                size,
                page,
                enterpriseId: that.queryId,
                productName: that.searchValue,
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            };
            that.$http.openEnterpriseProductList(data).then(res => {
                let { records } = res.data;
                records.forEach(item => {
                    item.productTypeStr = UnitAccount.getproductType(item.productType)
                })
                that.tabContainerList = records;
                that.total = res.data.total;
                that.currentPage = res.data.current;
            });
        },
        searchRelevantProduct() {
            this.openEnterpriseProductList()
        },
        searchBtn() {
            if (!this.searchValue) {
                return this.$common.message('请输入搜素内容！', 'warning')
            }
            this.openEnterpriseProductList()
        },
    }
}

</script>
<style lang='less' scoped>
.MoreProductList {
    .search-input {
        button {
            position: absolute;
            right: 0;
            width: 10%;
        }
    }
}
</style>
